<script>
import newsletterFormMixin from '@/v-shop/mixins/newsletter-form-mixin'

export default {
	props: {
		data: Object,
		index: Number,
	},
	mixins: [newsletterFormMixin()],
	computed: {
		img() {
			return this.$b.m ? this.data.imgMobile : this.data.img
		},
	},
	watch: {
		user() {
			if (this.user) {
				this.email = this.user.accountEmail
				this.firstname = this.user.firstname
			}
		},
	},
	mounted() {
		this.place = `page ${this.$route.path}`
		if (this.user) {
			this.email = this.user.accountEmail
			this.firstname = this.user.firstname
		}
	},
}
</script>

<template>
	<Container :class="$bem('dpc-newsletter')">
		<div class="d-flex align-center h100" :class="$b({ xs: 'pa-1' })">
			<div class="text-center w100">
				<h1 class="font-7 font-weight-thin" v-if="data.title">
					{{ data.title }}
				</h1>
				<div v-if="data.subtitle" class="font-weight-light font-4 mt-3">
					{{ data.subtitle }}
				</div>
				<v-container
					fluid
					class="py-4 d-flex align-center justify-center"
					:class="$bem('newsletter-form')"
				>
					<Validator v-bind="{ validation }">
						<form
							v-if="state != 'success'"
							@submit.prevent="submit()"
							class="d-flex align-center w100"
							:class="{ 'px-6 flex-column': $b.m, 'px-4 mt-2': $b.t }"
						>
							<TextField
								v-model="firstname"
								label="Nombre"
								solo
								flat
								class="my-2 mx-3"
								:class="{ w100: $b.m }"
								hide-details
							/>
							<TextField
								v-if="state != 'success'"
								v-model="email"
								label="Correo electrónico"
								hide-details
								solo
								flat
								class="my-2 mx-3"
								:class="{ 'w100 my-4': $b.m }"
							/>
							<Button
								type="submit"
								:loading="loading"
								class="rounded-xl font-weight-thin my-2"
								color="cta"
								:class="[$bem('__btn'), 'mr-2 px-12']"
							>
								Enviar
							</Button>
						</form>
					</Validator>
				</v-container>
			</div>
		</div>
	</Container>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.text-newsletter {
		width: 80%;
		line-height: 1.4;
		font-size: 1.2em;
	}
}

.newsletter-media {
	min-height: 400px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.newsletter-media {
		min-height: 300px;
	}
}

.newsletter-form .theme--light.v-text-field--solo ::v-deep .v-input__control > .v-input__slot {
	background: var(--secondary);
}

.form-newsletter {
	width: 85%;
}
</style>
