var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('Container',{class:_vm.$bem('carousel-multiple-pro')},[_c('v-row',{class:{
			'flex-column-reverse': _vm.data.inverted && _vm.hasBoardProducts && _vm.$b.mt,
			'flex-row-reverse': _vm.data.inverted && _vm.hasBoardProducts && _vm.$b.d,
			'mx-1': _vm.$b.mt,
		}},[(_vm.hasBoardProducts)?_c('v-col',{staticClass:"secondary px-5 pt-6 align-self-start",class:_vm.$bem('__featured-container'),attrs:{"cols":_vm.$b.d ? 4 : 12}},[_c('div',[_c('ProductsCarouselForFeaturedCard',_vm._b({attrs:{"title":_vm.data.title,"list-id":"related-products-board-products","products":_vm.data.boardProducts,"featured":"","timerProduct":_vm.limitedTimeOffer ? true : false}},'ProductsCarouselForFeaturedCard',
						_vm.$b.props({
							slidesPerPage: 1,
						})
					,false))],1)]):_vm._e(),_c('v-col',{attrs:{"cols":_vm.hasBoardProducts && _vm.$b.d ? 8 : 12}},[_c('v-row',{class:{ 'flex-row-reverse': _vm.right }},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":12}},[_c('v-tabs',{staticClass:"h100",class:{
							'carousel-multiple-pro__top-underline': _vm.$b.d,
							'carousel-multiple-pro__slider-top mb-3': true,
						},attrs:{"show-arrows":"","centered":"","center-active":"","color":_vm.$vars.cta,"slider-color":_vm.$vars.cta},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(item,n){return _c('v-tab',{key:n,class:{ 'justify-start': _vm.left, 'justify-end': _vm.right },attrs:{"active-class":"cta--text"}},[(_vm.data.titleType == 'icon')?_c('v-icon',[_vm._v(_vm._s(item.titleIcon))]):(_vm.data.titleType == 'img')?_c('Media',{attrs:{"src":item.titleImg}}):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-col',{staticClass:"py-0 d-flex",class:{
						'carousel-multiple-pro__border-left': _vm.left,
						'carousel-multiple-pro__border-right': _vm.right,
					},attrs:{"cols":"12","sm":_vm.top ? 12 : 10}},_vm._l((_vm.tabs),function(item,n){return _c('div',{key:n,staticClass:"tab",class:{ 'active-tab': n == _vm.tab }},[(n == _vm.tab)?_c('div',{key:n,staticClass:"pa-1",style:({ minHeight: _vm.$b.mt && '398px' })},[_c('div',{staticClass:"d-flex",class:{ 'justify-end': _vm.$b.d }},[_c('div',{staticClass:"d-flex",class:{ 'w100 carousel-multiple-pro__arrows-container-mobile': _vm.$b.mt }},[_c('span',{class:_vm.$bem('__btn-carousel-multiple-back'),style:({
											visibility: _vm.active > 0 ? 'visible' : 'hidden',
											pointerEvents: _vm.active > 0 ? 'all' : 'none',
										}),on:{"click":_vm.moveBack}},[_c('v-icon',{attrs:{"color":_vm.$vars.cta}},[_vm._v("mdi-chevron-left")])],1),_c('span',{class:_vm.$bem('__btn-carousel-multiple-next'),style:({
											visibility: _vm.active < _vm.lastIndex ? 'visible' : 'hidden',
											pointerEvents: _vm.active < _vm.lastIndex ? 'all' : 'none',
										}),on:{"click":_vm.moveForward}},[_c('v-icon',{attrs:{"color":_vm.$vars.cta}},[_vm._v("mdi-chevron-right")])],1)])]),_vm._l((item.products),function(el,index){return _c('div',{key:index,staticClass:"w100 tab",class:{ 'active-tab': _vm.active == index }},[(_vm.active == index)?_c('div',{staticClass:"d-flex flex-wrap",class:[_vm.$bem('__fade-enter-active'), _vm.$bem('__fade-leave-active')]},_vm._l((el),function(product,i){return _c('div',{key:i,staticClass:"d-flex flex-column",class:[
											_vm.$b.mt ? 'col-6' : 'col-4 px-2',
											{
												'pl-3 pr-1 align-center': _vm.$b.mt && i % 2 == 0,
												'pr-3 pl-1 align-center': _vm.$b.mt && i % 2 == 1,
											} ]},[_c('ProductCard',{attrs:{"product":product,"list-index":i,"list-id":"related-products","pbForBtnHover":"40px","small":_vm.$b.mt,"forMobileShop":_vm.$b.mt,"mobileBtn":_vm.$b.mt}})],1)}),0):_vm._e()])})],2):_vm._e()])}),0)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }